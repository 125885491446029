import { LoginOrSignup } from '../../components/LoginOrSignup';
import { CommonMetaHead } from '../../components/CommonMetaHead';
import { GetServerSidePropsContext, NextApiRequest } from 'next';
import { getServerSession } from 'next-auth';
import { getNextAuthOptions } from '../api/auth/[...nextauth]';
import { RouteEnum } from '@benefeature/shared-common';
import { ExposedDefaultLayout } from '../../components/Layouts/ExposedDefaultLayout';
import { calculateRedirect } from '../../core/calculateRedirect';

export const getServerSideProps = async (ctx: GetServerSidePropsContext) => {
  // Acquire the user session object
  const session = await getServerSession(ctx.req, ctx.res, getNextAuthOptions(ctx.req as NextApiRequest));

  // Calculate the appropriate redirect
  const redirectTarget = calculateRedirect(session, ctx.query.redirect as string);

  if (session?.user?.id) {
    return {
      redirect: {
        permanent: false,
        /* Redirect the user through the complete setup flow with passed params as applicable */
        destination: `${RouteEnum.AUTH_ACCOUNT_SETUP}?redirect=${encodeURIComponent(redirectTarget)}${
          ctx.query.price_id ? `&price_id=${ctx.query.price_id as string}` : ''
        }`,
      },
    };
  } else {
    return {
      props: {},
    };
  }
};

const AuthLogin = () => {
  const metaTitle = 'Sign In | Benefeature';
  const metaDescription = 'Sign in to Benefeature and get access to key benefit insights';

  return (
    <>
      <CommonMetaHead title={metaTitle} description={metaDescription} />

      <LoginOrSignup />
    </>
  );
};

AuthLogin.Layout = ExposedDefaultLayout;

export default AuthLogin;
